import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Button, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  createPackage,
  packageSelector,
  searchCategories,
} from "../../../services/package/package-slice.service";
import FormFieldLabel from "../../business/components/form-field-label.component";
import CategoryMultipleSelect from "../components/category-multiple-select.component";
import CategorySingleSelect from "../components/category-single-select.component";
import ImageUpload from "../components/image-upload.component";
import SpecificListAutoComplete from "../components/specific-list-autocomplete.component";
import SubscriptionPlansListAutoComplete from "../components/subscription-plans-list-autocomplete.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function PackageCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryType, setCategoryType] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const { searchCategoriesObj, createPackageObj } = useSelector(packageSelector);

  const DetailTextField = styled(Field)({
    borderRadius: theme.shape.borderRadius[0],
    padding: "10px",
    font: "inherit",
    backgroundColor: theme.palette.colors.bg.primary,
    border: "2px solid #D0D0D0",
    "& .MuiOutlinedInput-root": {
      borderRadius: theme.shape.borderRadius[0],
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    "&:hover": {
      border: "2px solid black",
    },
  });

  const validationSchema = Yup.object().shape({
    businesses: Yup.array().required().min(1).label("Businesses"),
    name: Yup.string()
      .required()
      .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
      .label("Name"),
    priceRM: Yup.number().required().label("Price"),
    membershipPriceRM: Yup.number()
      .lessThan(Yup.ref("priceRM"))
      .nullable()
      .label("Membership Price"),
    noOfSessions: Yup.number().required().label("No. of Sessions"),
    categoryModel: Yup.string().required().label("Category Type"),
    categoryId: Yup.array(Yup.number().required()).min(1).label("Categories"),
    subscriptions: Yup.array().label("Subscriptions"),
    image: Yup.string().required().label("Image"),
    details: Yup.array(Yup.string().required("Must have at least 1 detail")).min(1).label("Detail"),
    expireNoDaysFromPurchaseDate: Yup.number()
      .nullable()
      .label("Expire session after purchase (Days)"),
  });

  const renderCategories = () => {
    if (categoryType === "Fitness") return searchCategoriesObj.data.fitness;
    if (categoryType === "Wellness") return searchCategoriesObj.data.wellness;
    if (categoryType === "Health") return searchCategoriesObj.data.health;
    return [];
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(searchCategories("")).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onCreatePackage = (values) => {
    const priceCents = values.priceRM * 100;
    const membershipPriceCents = values.membershipPriceRM ? values.membershipPriceRM * 100 : null;
    const businessesId = values.businesses.map((item) => item.id);
    const subscriptionPlansId = values.subscriptions.map((item) => item.id);

    setIsLoading(true);
    dispatch(
      createPackage({
        priceCents,
        membershipPriceCents,
        businessesId,
        subscriptionPlansId,
        ...values,
      }),
    ).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.PACKAGE_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      <Formik
        validationSchema={validationSchema}
        onSubmit={onCreatePackage}
        initialValues={{
          businesses: [],
          name: "",
          priceRM: "",
          membershipPriceRM: "",
          noOfSessions: "",
          expireNoDaysFromPurchaseDate: "",
          categoryModel: "",
          categoryId: [],
          category: {
            model: "",
            ids: [],
          },
          subscriptions: [],
          image: "",
          details: [""],
        }}
      >
        {({ values, errors }) => (
          <Form>
            <Grid
              container
              spacing={5}
              sx={{
                padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
                paddingY: theme.dimensions.ScreenPaddingY,
              }}
            >
              <Grid item xs={12}>
                <SpaceBetweenBox>
                  <Text variant="screenLabel">Create Package Plan</Text>
                  <Button
                    onClick={() => history.push(routes.PACKAGE_LIST)}
                    sx={{
                      textTransform: "none",
                      padding: "0px",
                      ":hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Text>Back</Text>
                  </Button>
                </SpaceBetweenBox>
              </Grid>

              <Grid item xs={12}>
                <WhiteBgCardContainer>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <Grid item xs={12}>
                      <Text variant="screenLabel">Package Plan</Text>
                    </Grid>
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Businesses</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Businesses
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <SpecificListAutoComplete name="businesses" placeholder="Businesses" />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <FormFieldLabel
                        name="name"
                        placeholder="Enter package name"
                        label="Package Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormPrice placeholder="00.00" name="priceRM" />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Membership Price
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Membership Price
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormPrice placeholder="00.00" name="membershipPriceRM" />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            No. of Sessions
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              No. of Sessions
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormFieldText
                              name="noOfSessions"
                              placeholder="0"
                              label="No. of Sessions"
                              type="number"
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      {isMobile && (
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                          Expire session after purchase (Days)
                        </Text>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Expire session after purchase (Days)
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormFieldText
                              name="expireNoDaysFromPurchaseDate"
                              placeholder="0"
                              label="Expire session after purchase (Days)"
                              type="number"
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>{" "}
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Category Type
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Category Type
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <CategorySingleSelect
                              name="categoryModel"
                              placeholder="Choose category type"
                              label="Category Type"
                              itemList={[
                                { id: 1, label: "Fitness" },
                                { id: 2, label: "Wellness" },
                                { id: 3, label: "Health" },
                              ]}
                              setCategoryType={setCategoryType}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Category(s)</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Category(s)
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <CategoryMultipleSelect
                              name="categoryId"
                              placeholder="Choose categories"
                              itemList={renderCategories()}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    {categoryType === "Fitness" && (
                      <Grid item xs={12}>
                        {isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Subscription(s)
                            </Text>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Subscription(s)
                              </Text>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <SubscriptionPlansListAutoComplete
                                name="subscriptions"
                                placeholder="Subscription Plans"
                              />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <ImageUpload
                              name="image"
                              filePreview={filePreview}
                              setFilePreview={setFilePreview}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <FieldArray name="details">
                      {({ push, remove }) => (
                        <>
                          <Grid item xs={12}>
                            <FormContainer>
                              <LabelContainer>
                                <SpaceBetweenBox>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Details
                                  </Text>
                                  <CtaButton onClickButton={() => push("")}>
                                    <Text type="WhiteColor">Add</Text>
                                  </CtaButton>
                                </SpaceBetweenBox>
                              </LabelContainer>
                            </FormContainer>
                          </Grid>

                          {values.details.map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Grid container item key={index}>
                              <Grid item xs={11.5}>
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <DetailTextField
                                    name={`details[${index}]`}
                                    placeholder="Enter package details"
                                    type="text"
                                  />
                                  {errors.details && (
                                    <FormHelperText error sx={{ marginLeft: "10px" }}>
                                      {errors.details[0]}
                                    </FormHelperText>
                                  )}
                                </Box>
                              </Grid>
                              <Grid item xs={0.5}>
                                <IconButton
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <RemoveCircleIcon
                                    sx={{ color: theme.palette.colors.text.rejected }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </WhiteBgCardContainer>
              </Grid>
              <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
                <FormSubmitButton width="200px" isLoading={createPackageObj.status === "pending"}>
                  <Text type="WhiteColor">Create</Text>
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

/* eslint-disable no-useless-escape */
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormPrice from "../../../components/forms/form-price.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import FormFieldLabel from "../../business/components/form-field-label.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import {
  getSubscription,
  subscriptionSelector,
  updateSubscription,
  updateSubscriptionImage,
  updateSubscriptionStatus,
} from "../../../services/subscription/subscription-slice.service";
import ImageUpload from "../components/image-upload.component";
import SpecificListAutoComplete from "../components/specific-list-autocomplete.component";
import StatusSingleSelect from "../components/status-single-select.component";
import TypeSingleSelect from "../components/type-single-select.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function SubscriptionEditScreen() {
  const theme = useTheme();
  const location = useLocation();
  const selectedPlanId = location.state.id;
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [filePreview, setFilePreview] = useState(location.state.imagePath);
  const { getSubscriptionObj } = useSelector(subscriptionSelector);
  const [allowEditPlan, setAllowEditPlan] = useState(false);
  const [allowEditStatus, setAllowEditStatus] = useState(false);
  const [allowEditImage, setAllowEditImage] = useState(false);

  const validationSchema = Yup.object().shape({
    businesses: Yup.array().required().min(1).label("Businesses"),
    title: Yup.string()
      .required()
      .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allowed")
      .label("Title"),
    shortDescription: Yup.string().required().label("Short Description"),
    description: Yup.string().required().label("Description"),
    priceRM: Yup.number().required().label("Price"),
    membershipPriceRM: Yup.number()
      .lessThan(Yup.ref("priceRM"))
      .nullable()
      .label("Membership Price"),
    strikePriceRM: Yup.number().nullable().label("Strike Price"),
    numberOfDays: Yup.number().required().label("Number of days"),
    expireNoDaysFromPurchaseDate: Yup.number().nullable().label("Expire after purchase (Days)"),
    type: Yup.string().required().label("Type"),
    totalRecurrence: Yup.number().when("type", {
      is: (val) => val === "recurrent",
      then: Yup.number().required().nullable().label("Number of Recurrence (Months)"),
      otherwise: Yup.number().nullable().label("Number of Recurrence (Months)"),
    }),
  });

  const validationSchemaStatus = Yup.object().shape({
    statusAlias: Yup.string().required().label("Status"),
  });

  const validationSchemaImage = Yup.object().shape({
    image: Yup.string().required().label("Image"),
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(getSubscription({ planId: selectedPlanId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdateSubscription = (values) => {
    const priceCents = values.priceRM * 100;
    const membershipPriceCents = values.membershipPriceRM ? values.membershipPriceRM * 100 : null;
    const strikePriceCents = values.strikePriceRM * 100;
    const businessesId = values.businesses.map((item) => item.id);
    if (values.type === "one_off") values.totalRecurrence = null;
    setIsLoading(true);
    dispatch(
      updateSubscription({
        planId: selectedPlanId,
        priceCents,
        membershipPriceCents,
        strikePriceCents,
        businessesId,
        ...values,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditPlan(false);
  };

  const onUpdateSubscriptionStatus = (values) => {
    setIsLoading(true);
    dispatch(updateSubscriptionStatus({ planId: selectedPlanId, ...values })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        setIsLoading(false);
      },
    );
    setAllowEditStatus(false);
  };

  const onUpdateSubscriptionImage = (values) => {
    setIsLoading(true);
    dispatch(updateSubscriptionImage({ planId: selectedPlanId, ...values })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        setIsLoading(false);
      },
    );
    setAllowEditImage(false);
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      {getSubscriptionObj.status === "succeeded" && (
        <>
          <Formik
            validationSchema={validationSchemaImage}
            onSubmit={onUpdateSubscriptionImage}
            initialValues={{
              image: getSubscriptionObj.data.imagePath,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                    paddingTop: theme.dimensions.ScreenPaddingY,
                  }}
                >
                  <Grid item xs={12}>
                    <SpaceBetweenBox>
                      <Text variant="screenLabel">Edit Subscription Plan</Text>
                      <Button
                        onClick={() => history.push(routes.SUBSCRIPTION_LIST)}
                        sx={{
                          textTransform: "none",
                          padding: "0px",
                          ":hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <Text>Back</Text>
                      </Button>
                    </SpaceBetweenBox>
                  </Grid>
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Image</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditImage ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditImage(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditImage(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <ImageUpload
                                  name="image"
                                  disabled={!allowEditImage}
                                  filePreview={filePreview}
                                  setFilePreview={setFilePreview}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Formik
            validationSchema={validationSchemaStatus}
            onSubmit={onUpdateSubscriptionStatus}
            initialValues={{
              statusAlias:
                getSubscriptionObj.data.status.charAt(0).toUpperCase() +
                getSubscriptionObj.data.status.slice(1),
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Status</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditStatus ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditStatus(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditStatus(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Status</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Status
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <StatusSingleSelect
                                  name="statusAlias"
                                  placeholder="Choose status"
                                  label="Status"
                                  disabled={!allowEditStatus}
                                  itemList={[
                                    { id: 1, label: "Active" },
                                    { id: 2, label: "Pending" },
                                    { id: 3, label: "Inactive" },
                                  ]}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchema}
            onSubmit={onUpdateSubscription}
            initialValues={{
              businesses: getSubscriptionObj.data.businesses,
              title: getSubscriptionObj.data.title,
              shortDescription: getSubscriptionObj.data.shortDescription,
              description: getSubscriptionObj.data.description,
              priceRM: Number(getSubscriptionObj.data.price.replace(/[^0-9\.]+/g, "")).toFixed(2),
              membershipPriceRM:
                getSubscriptionObj.data.membershipPrice !== null
                  ? Number(
                      getSubscriptionObj.data.membershipPrice.replace(/[^0-9\.]+/g, ""),
                    ).toFixed(2)
                  : null,
              strikePriceRM: getSubscriptionObj.data.strikePrice
                ? Number(getSubscriptionObj.data.strikePrice.replace(/[^0-9\.]+/g, "")).toFixed(2)
                : "",
              numberOfDays: getSubscriptionObj.data.numberOfDays,
              expireNoDaysFromPurchaseDate: getSubscriptionObj.data.expireNoDaysFromPurchaseDate
                ? getSubscriptionObj.data.expireNoDaysFromPurchaseDate
                : "",
              type: getSubscriptionObj.data.type,
              totalRecurrence: getSubscriptionObj.data.totalRecurrence,
            }}
          >
            {({ handleSubmit, dirty, values }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Plan</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditPlan ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditPlan(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditPlan(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Businesses
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Businesses
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <SpecificListAutoComplete
                                  name="businesses"
                                  placeholder="Businesses"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={12}>
                          <FormFieldLabel
                            name="title"
                            placeholder="Enter subscription title"
                            label="Subscription Title"
                            disabled={!allowEditPlan}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Short Description
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Short Description
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormFieldTextMultiline
                                  placeholder="Enter short description"
                                  name="shortDescription"
                                  rows={2}
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Description
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Description
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormFieldTextMultiline
                                  placeholder="Enter description"
                                  name="description"
                                  rows={5}
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormPrice
                                  placeholder="00.00"
                                  name="priceRM"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Membership Price
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Membership Price
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormPrice
                                  placeholder="00.00"
                                  name="membershipPriceRM"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Strike Price
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Strike Price
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormPrice
                                  placeholder="00.00"
                                  name="strikePriceRM"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Number of days
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Number of days
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormFieldText
                                  name="numberOfDays"
                                  placeholder="0"
                                  label="Number of days"
                                  type="number"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Expire after purchase (Days)
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Expire after purchase (Days)
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormFieldText
                                  name="expireNoDaysFromPurchaseDate"
                                  placeholder="0"
                                  label="Expire after purchase (Days)"
                                  type="number"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Type</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Type</Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <TypeSingleSelect
                                  name="type"
                                  placeholder="Choose type"
                                  label="Type"
                                  disabled={!allowEditPlan}
                                  itemList={[
                                    { id: 1, label: "One-Off", value: "one_off" },
                                    { id: 2, label: "Recurrent", value: "recurrent" },
                                  ]}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        {values.type === "recurrent" && (
                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Number of Recurrence (Months)
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Number of Recurrence (Months)
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormFieldText
                                    name="totalRecurrence"
                                    placeholder="0"
                                    label="Number of Recurrence (Months)"
                                    type="number"
                                    disabled={!allowEditPlan}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                        )}
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
}

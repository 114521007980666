import InfoIcon from "@mui/icons-material/Info";
import { Box, Grid, Modal, styled, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { fitnessSessionSelector } from "../../../services/fitness/sessions/session.slice.services";
import { staffSelector } from "../../../services/staff/staff-slice.service";
import ClassListAutoCompleteSingleSelect from "./class-list-autocomplete-single-select.component";
import SessionStaffAutoComplete from "./session-staff-autocomplete.component";
import StatusSingleSelect from "./status-single-select.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxHeight: "90%",
  overflowY: "scroll",
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  color: theme.palette.colors.brand.primary,
}));

const CenteredRowBox = styled(Box)({ display: "flex", flexDirection: "row", alignItems: "center" });

const STATUSLIST = [
  { id: 1, label: "Publish", value: "publish" },
  { id: 2, label: "Draft", value: "draft" },
];

export default function SessionDuplicateModal({
  showModal,
  setShowModal,
  keyword,
  setKeyword,
  setDuplicateSessionInfo,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm, values, setFieldValue } = useFormikContext();
  const { addSessionObj } = useSelector(fitnessSessionSelector);
  const { searchStaffsObj } = useSelector(staffSelector);

  const handleCancel = () => {
    resetForm();
    setDuplicateSessionInfo(null);
    setShowModal(false);
  };

  useEffect(() => {
    if (values.occurenceType === "daily") {
      setFieldValue("weeklyRepeat", null);
    }
  }, [values.occurenceType]);

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "80%",
          maxWidth: "700px",
        }}
      >
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12}>
            <Spacer />
            <Text variant="screenLabel">Duplicate Session</Text>
          </Grid>
          <>
            <Grid item xs={12}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Class</Text>
              <Spacer />
              <ClassListAutoCompleteSingleSelect name="classId" placeholder="Add class" />
            </Grid>
            <Grid item xs={12}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Date Time</Text>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormDatePicker name="startDate" width="100%" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormTimePicker name="startTime" />
            </Grid>
            <Grid item xs={12}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Date Time</Text>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormDatePicker name="endDate" width="100%" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormTimePicker name="endTime" />
            </Grid>
          </>

          <Grid item container xs={12} columnSpacing={3} rowSpacing={1}>
            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <CenteredRowBox>
                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Capacity</Text>
                <Spacer position="left" size="s" />
                <Tooltip title="0 stands for unlimited capacity" arrow>
                  <StyledInfoIcon />
                </Tooltip>
              </CenteredRowBox>
              <Spacer />
              <FormFieldText placeholder="Enter capacity" name="maxCapacity" />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
              <Spacer />
              <FormPrice placeholder="00.00" name="price" />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Membership Price</Text>
              <Spacer />
              <FormPrice placeholder="00.00" name="membershipPrice" />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <CenteredRowBox>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Status</Text>
              <Spacer position="left" size="s" />
              <Tooltip title="Session will no longer be editable once published" arrow>
                <StyledInfoIcon />
              </Tooltip>
            </CenteredRowBox>
            <Spacer />
            <StatusSingleSelect
              name="status"
              placeholder="Choose status"
              label="Status"
              itemList={STATUSLIST}
            />
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Staff</Text>
            <Spacer />
            <SessionStaffAutoComplete
              placeholder="Type to add staff"
              name="staffObj"
              keyword={keyword}
              setKeyword={setKeyword}
              optionList={searchStaffsObj.data || []}
            />
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={handleCancel}
                width="100px"
                isLoading={addSessionObj.status === "pending"}
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton width="100px" isLoading={addSessionObj.status === "pending"}>
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

SessionDuplicateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
  setKeyword: PropTypes.func.isRequired,
  setDuplicateSessionInfo: PropTypes.func.isRequired,
};

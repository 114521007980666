import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import {
  fitnessSessionSelector,
  getSession,
  toggleSessionStatus,
  updateSession,
} from "../../../services/fitness/sessions/session.slice.services";
import { searchStaffs, staffSelector } from "../../../services/staff/staff-slice.service";
import SessionStaffAutoComplete from "../components/session-staff-autocomplete.component";
import StatusSingleSelect from "../components/status-single-select.component";
import routes from "../../../navigation/routes";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const InfoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: "36px",
  color: theme.palette.colors.brand.primary,
}));

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const STATUSLIST_PUBLISH_DRAFT = [
  { id: 1, label: "Publish", value: "publish" },
  { id: 2, label: "Draft", value: "draft" },
];

const STATUSLIST_PUBLISH_CANCELLED = [
  { id: 1, label: "Publish", value: "publish" },
  { id: 2, label: "Cancelled", value: "cancelled" },
];

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required().label("Start date"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .label("End Date")
    .nullable(),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  maxCapacity: Yup.number().required().label("Capacity"),
  priceRM: Yup.number().min(0).typeError("Price is required").label("Price"),
  membershipPriceRM: Yup.number().lessThan(Yup.ref("priceRM")).nullable().label("Membership Price"),
  staffObj: Yup.array()
    .of(Yup.object().shape({ id: Yup.number().required(), name: Yup.string().required() }))
    .min(1)
    .label("Staff"),
});

const validationSchemaStatus = Yup.object().shape({
  status: Yup.array().label("Status").nullable(),
});

export default function FitnessClassSessionEditScreen() {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const selectedSessionId = location.state.id;
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [staffKeyword, setStaffKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { searchStaffsObj } = useSelector(staffSelector);
  const { getSessionObj } = useSelector(fitnessSessionSelector);
  const [allowEditDetail, setAllowEditDetail] = useState(false);
  const [allowEditStatus, setAllowEditStatus] = useState(false);

  const onChangeSearchStaff = (value) => {
    setStaffKeyword(value);
    dispatch(searchStaffs({ q: value })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(searchStaffs({ q: staffKeyword })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setIsLoading(true);
    dispatch(getSession({ sessionId: selectedSessionId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdateSession = (values) => {
    const amountCents = values.priceRM * 100;
    const membershipAmountCents = values.membershipPriceRM ? values.membershipPriceRM * 100 : null;
    const startTimeFormatted = dayjs(values.startTime).format("HH:mm:00");
    const endTimeFormatted = dayjs(values.endTime).format("HH:mm:00");
    const startDateFormatted = format(values.startDate, "yyyy-MM-dd");
    const endDateFormatted = format(values.endDate, "yyyy-MM-dd");
    const staffIds = [];
    values.staffObj.map((item) => staffIds.push(item.id));

    setIsLoading(true);
    dispatch(
      updateSession({
        sessionId: selectedSessionId,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
        amountCents,
        membershipAmountCents,
        maxCapacity: values.maxCapacity,
        staffIds,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditDetail(false);
  };

  const onUpdateSessionStatus = (values) => {
    setIsLoading(true);
    dispatch(
      toggleSessionStatus({
        sessionIds: [selectedSessionId],
        status: values.status,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        if (values.status === "publish" || values.status === "cancelled") {
          window.location.reload();
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditStatus(false);
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      {getSessionObj.status === "succeeded" && (
        <>
          <Formik
            validationSchema={validationSchema}
            onSubmit={onUpdateSession}
            initialValues={{
              startDate: moment(getSessionObj.data.startAt).toDate(),
              endDate: moment(getSessionObj.data.endAt).toDate(),
              startTime: dayjs(getSessionObj.data.startTime, "HH:mmA"),
              endTime: dayjs(getSessionObj.data.endTime, "HH:mmA"),
              maxCapacity: getSessionObj.data.maxCapacity,
              priceRM: getSessionObj.data.price,
              membershipPriceRM:
                getSessionObj.data.membershipPrice !== null
                  ? parseFloat(getSessionObj.data.membershipPrice.replaceAll(",", ""))
                  : null,
              staffObj: getSessionObj.data.staffs,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                    paddingTop: theme.dimensions.ScreenPaddingY,
                  }}
                >
                  <Grid item xs={12}>
                    <SpaceBetweenBox>
                      <Text variant="screenLabel">Edit Session</Text>
                      <Button
                        onClick={() => history.push(routes.FITNESS_CLASS_SESSIONS)}
                        sx={{
                          textTransform: "none",
                          padding: "0px",
                          ":hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <Text>Back</Text>
                      </Button>
                    </SpaceBetweenBox>
                  </Grid>
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid
                        container
                        columnSpacing={isMobile ? 1 : 10}
                        rowSpacing={isMobile ? 1 : 3}
                      >
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Detail</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditDetail ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditDetail(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              {getSessionObj.data.status === "draft" && (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setAllowEditDetail(true);
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              )}
                            </>
                          )}
                        </Grid>

                        <Grid
                          item
                          container
                          columnSpacing={isMobile ? 1 : 10}
                          rowSpacing={isMobile ? 1 : 3}
                        >
                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Start Date
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Start Date
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormDatePicker
                                    name="startDate"
                                    width="100%"
                                    disabled={!allowEditDetail}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Start Time
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Start Time
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormTimePicker name="startTime" disabled={!allowEditDetail} />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  End Date
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    End Date
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormDatePicker
                                    name="endDate"
                                    width="100%"
                                    disabled={!allowEditDetail}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  End Time
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    End Time
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormTimePicker name="endTime" disabled={!allowEditDetail} />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Price
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormPrice
                                    placeholder="00.00"
                                    name="priceRM"
                                    disabled={!allowEditDetail}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Membership Price
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Membership Price
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormPrice
                                    placeholder="00.00"
                                    name="membershipPriceRM"
                                    disabled={!allowEditDetail}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Capacity
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Capacity
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormFieldText
                                    placeholder="Enter capacity"
                                    name="maxCapacity"
                                    disabled={!allowEditDetail}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>

                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Staffs
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Staffs
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <SessionStaffAutoComplete
                                    placeholder="Type to add staff"
                                    name="staffObj"
                                    keyword={staffKeyword}
                                    setKeyword={onChangeSearchStaff}
                                    optionList={searchStaffsObj?.data}
                                    disabled={!allowEditDetail}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchemaStatus}
            onSubmit={onUpdateSessionStatus}
            initialValues={{
              status: getSessionObj.data.status,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid
                        container
                        columnSpacing={isMobile ? 1 : 10}
                        rowSpacing={isMobile ? 1 : 3}
                      >
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Status</Text>
                        </Grid>

                        <Grid item xs={4} sm={2}>
                          {allowEditStatus ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditStatus(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              {getSessionObj.data.status !== "cancelled" && (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setAllowEditStatus(true);
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              )}
                            </>
                          )}
                        </Grid>
                        {getSessionObj.data.status !== "cancelled" && (
                          <Grid item container>
                            <Grid item xs={isMobile ? 12 : 6}>
                              <InfoContainer sx={{ padding: isMobile ? "15px" : "8px" }}>
                                <Grid item xs={2}>
                                  <StyledInfoIcon />
                                </Grid>
                                <Grid item xs={10}>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    {getSessionObj.data.status === "draft" &&
                                      "Once publish, the details can no longer be edited."}
                                    {getSessionObj.data.status === "publish" &&
                                      "Cancelling the session will refund the credits over to the users that purchased."}
                                  </Text>
                                </Grid>
                              </InfoContainer>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item container>
                          <Grid item xs={isMobile ? 12 : 6}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Status
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Status
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <StatusSingleSelect
                                    name="status"
                                    placeholder="Choose status"
                                    label="Status"
                                    itemList={
                                      getSessionObj.data.status === "draft"
                                        ? STATUSLIST_PUBLISH_DRAFT
                                        : STATUSLIST_PUBLISH_CANCELLED
                                    }
                                    disabled={!allowEditStatus}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import SessionStaffAutoComplete from "./session-staff-autocomplete.component";
import { fitnessSessionSelector } from "../../../services/fitness/sessions/session.slice.services";
import { staffSelector } from "../../../services/staff/staff-slice.service";
import FormFieldDropdown from "../../../components/forms/form-field-dropdown.component";
import FormMultipleToggleButton from "../../../components/forms/form-multiple-toggle-button.component";
import FormFieldTextWithCustomisedAdornments from "../../../components/forms/form-field-text-with-customised-adornments.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import StatusSingleSelect from "./status-single-select.component";
import ClassListAutoCompleteSingleSelect from "./class-list-autocomplete-single-select.component";
import FormDatePickerMultiple from "../../../components/forms/form-date-picker-multiple.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxHeight: "90%",
  overflowY: "scroll",
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  color: theme.palette.colors.brand.primary,
}));

const CenteredRowBox = styled(Box)({ display: "flex", flexDirection: "row", alignItems: "center" });

const STATUSLIST = [
  { id: 1, label: "Publish", value: "publish" },
  { id: 2, label: "Draft", value: "draft" },
];

export default function SessionCreateModal({ showModal, setShowModal, keyword, setKeyword }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm, values, setFieldValue } = useFormikContext();
  const { addSessionObj, updateSessionObj } = useSelector(fitnessSessionSelector);
  const [endType, setEndType] = useState("endDate");
  const { searchStaffsObj } = useSelector(staffSelector);
  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  useEffect(() => {
    if (values.occurenceType === "daily") {
      setFieldValue("weeklyRepeat", null);
    }
  }, [values.occurenceType]);

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "80%",
          maxWidth: "700px",
        }}
      >
        <Grid container columnSpacing={3} rowSpacing={1}>
          {!values.sessionId && (
            <Grid item xs={isMobile ? 12 : 4}>
              <Spacer />
              <ToggleButtonGroup
                value={values.createType}
                exclusive
                onChange={(e) => {
                  setFieldValue("createType", e.target.value);
                }}
              >
                <ToggleButton value="one-off" sx={{ whiteSpace: "nowrap" }}>
                  One-Off
                </ToggleButton>
                <ToggleButton value="recurrent" sx={{ whiteSpace: "nowrap" }}>
                  Recurrent
                </ToggleButton>
                <ToggleButton value="custom" sx={{ whiteSpace: "nowrap" }}>
                  Custom
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}

          <Grid item xs={12}>
            <Spacer />
            <Text variant="screenLabel">
              {!values.sessionId ? "Add Session" : "Update Session"}
            </Text>
          </Grid>
          <>
            <Grid item xs={12}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Class</Text>
              <Spacer />
              <ClassListAutoCompleteSingleSelect name="classId" placeholder="Add class" />
            </Grid>
            {values.createType === "one-off" && (
              <>
                <Grid item xs={12}>
                  <Spacer />
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Date</Text>
                </Grid>
                <Grid item xs={12}>
                  <FormDatePicker name="startDate" width="100%" />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <Spacer />
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                  <Spacer />
                  <FormTimePicker name="startTime" />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <Spacer />
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                  <Spacer />
                  <FormTimePicker name="endTime" />
                </Grid>
              </>
            )}
          </>

          <>
            {values.createType === "recurrent" && (
              <>
                <Grid item container xs={12} columnSpacing={3} rowSpacing={1}>
                  <Grid item xs={isMobile ? 12 : 6}>
                    <Spacer />
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                    <Spacer />
                    <FormTimePicker name="startTime" />
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    <Spacer />
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                    <Spacer />
                    <FormTimePicker name="endTime" />
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    <Spacer />
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Repeats</Text>
                    <Spacer />
                    <FormFieldDropdown
                      name="occurenceType"
                      itemList={[
                        { id: "daily", label: "Daily" },
                        { id: "weekly", label: "Weekly" },
                      ]}
                    />
                  </Grid>
                </Grid>

                {values.occurenceType === "weekly" && (
                  <>
                    <Grid item xs={12}>
                      <Spacer />
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Repeat on</Text>
                      <Spacer />
                      <FormMultipleToggleButton
                        name="weeklyRepeat"
                        size={isMobile ? "small" : "large"}
                        itemList={[
                          { id: 0, value: 7, label: "Sun" },
                          { id: 1, value: 1, label: "Mon" },
                          { id: 2, value: 2, label: "Tue" },
                          { id: 3, value: 3, label: "Wed" },
                          { id: 4, value: 4, label: "Thu" },
                          { id: 5, value: 5, label: "Fri" },
                          { id: 6, value: 6, label: "Sat" },
                        ]}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={isMobile ? 12 : 6}>
                  <Spacer />
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Date</Text>
                  <Spacer />
                  <FormDatePicker name="startDate" width="100%" />
                </Grid>

                <Grid item xs={12}>
                  <Spacer />
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Ends</Text>
                  <Spacer />
                  <Grid container>
                    <RadioGroup
                      value={endType}
                      onChange={(e) => {
                        setFieldValue("endType", e.target.value);
                        setEndType(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="occurenceNumber"
                        control={<Radio />}
                        label={
                          <Grid
                            item
                            container
                            xs={12}
                            sx={{ alignItems: "center", width: isMobile ? "100%" : 350 }}
                          >
                            <Grid item xs={isMobile ? 3 : 5}>
                              <Text type={endType === "occurenceNumber" ? "" : "GreyColor"}>
                                After
                              </Text>
                            </Grid>
                            <Grid item xs={isMobile ? 9 : 7}>
                              {isMobile && <Spacer />}
                              <FormFieldTextWithCustomisedAdornments
                                disabled={endType === "endDate"}
                                name="occurenceNumber"
                                placeholder="0"
                                type="number"
                                endAdornment={
                                  <>
                                    <Text type={endType === "occurenceNumber" ? "" : "GreyColor"}>
                                      occurences
                                    </Text>
                                  </>
                                }
                              />
                            </Grid>
                          </Grid>
                        }
                      />
                      <Spacer />
                      <FormControlLabel
                        value="endDate"
                        control={<Radio />}
                        label={
                          <Grid
                            item
                            container
                            xs={12}
                            sx={{ alignItems: "center", width: isMobile ? "100%" : 350 }}
                          >
                            <Grid item xs={isMobile ? 4 : 5}>
                              <Text type={endType === "endDate" ? "" : "GreyColor"}>
                                Specified Date
                              </Text>
                            </Grid>
                            <Grid item xs={isMobile ? 8 : 7}>
                              {isMobile && <Spacer />}
                              <FormDatePicker
                                disabled={endType === "occurenceNumber"}
                                name="endDate"
                              />
                            </Grid>
                          </Grid>
                        }
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </>
            )}
          </>

          {values.createType === "custom" && (
            <>
              <Grid item xs={12}>
                <Spacer />
                <CenteredRowBox>
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Dates</Text>
                  <Spacer position="left" size="s" />
                  <Tooltip title="Only Daily" arrow>
                    <StyledInfoIcon />
                  </Tooltip>
                </CenteredRowBox>
                <Spacer />
                <FormDatePickerMultiple name="dates" width="100%" />
              </Grid>

              <Grid item xs={isMobile ? 12 : 6}>
                <Spacer />
                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                <Spacer />

                <FormTimePicker name="startTime" />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Spacer />
                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                <Spacer />

                <FormTimePicker name="endTime" />
              </Grid>
            </>
          )}

          <Grid item container xs={12} columnSpacing={3} rowSpacing={1}>
            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <CenteredRowBox>
                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Capacity</Text>
                <Spacer position="left" size="s" />
                <Tooltip title="0 stands for unlimited capacity" arrow>
                  <StyledInfoIcon />
                </Tooltip>
              </CenteredRowBox>
              <Spacer />
              <FormFieldText placeholder="Enter capacity" name="maxCapacity" />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
              <Spacer />
              <FormPrice placeholder="00.00" name="price" />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Membership Price</Text>
              <Spacer />
              <FormPrice placeholder="00.00" name="membershipPrice" />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <CenteredRowBox>
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Status</Text>
              <Spacer position="left" size="s" />
              <Tooltip title="Session will no longer be editable once published" arrow>
                <StyledInfoIcon />
              </Tooltip>
            </CenteredRowBox>
            <Spacer />
            <StatusSingleSelect
              name="status"
              placeholder="Choose status"
              label="Status"
              itemList={STATUSLIST}
            />
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Staff</Text>
            <Spacer />
            <SessionStaffAutoComplete
              placeholder="Type to add staff"
              name="staffObj"
              keyword={keyword}
              setKeyword={setKeyword}
              optionList={searchStaffsObj.data || []}
            />
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={handleCancel}
                width="100px"
                isLoading={
                  addSessionObj.status === "pending" || updateSessionObj.status === "pending"
                }
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton
                width="100px"
                isLoading={
                  addSessionObj.status === "pending" || updateSessionObj.status === "pending"
                }
              >
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

SessionCreateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
  setKeyword: PropTypes.func.isRequired,
};

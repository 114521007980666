import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  createSubscription,
  subscriptionSelector,
} from "../../../services/subscription/subscription-slice.service";
import FormFieldLabel from "../../business/components/form-field-label.component";
import ImageUpload from "../components/image-upload.component";
import SpecificListAutoComplete from "../components/specific-list-autocomplete.component";
import TypeSingleSelect from "../components/type-single-select.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function SubscriptionCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [filePreview, setFilePreview] = useState("");
  const { createSubscriptionObj } = useSelector(subscriptionSelector);

  const validationSchema = Yup.object().shape({
    businesses: Yup.array().required().min(1).label("Businesses"),
    title: Yup.string()
      .required()
      .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allowed")
      .label("Title"),
    shortDescription: Yup.string().required().label("Short Description"),
    description: Yup.string().required().label("Description"),
    priceRM: Yup.number().required().label("Price"),
    membershipPriceRM: Yup.number()
      .lessThan(Yup.ref("priceRM"))
      .nullable()
      .label("Membership Price"),
    strikePriceRM: Yup.number().nullable().label("Strike Price"),
    numberOfDays: Yup.number().required().label("Number of days"),
    expireNoDaysFromPurchaseDate: Yup.number().nullable().label("Expire after purchase (Days)"),
    type: Yup.string().required().label("Type"),
    totalRecurrence: Yup.number().when("type", {
      is: (val) => val === "recurrent",
      then: Yup.number().required().nullable().label("Number of Recurrence (Months)"),
      otherwise: Yup.number().nullable().label("Number of Recurrence (Months)"),
    }),
    image: Yup.string().required().label("Image"),
  });

  const onCreateSubscription = (values) => {
    const priceCents = values.priceRM * 100;
    const membershipPriceCents = values.membershipPriceRM ? values.membershipPriceRM * 100 : null;
    const strikePriceCents = values.strikePriceRM * 100;
    const businessesId = values.businesses.map((item) => item.id);
    if (values.type === "one_off") values.totalRecurrence = null;
    setIsLoading(true);
    dispatch(
      createSubscription({
        priceCents,
        membershipPriceCents,
        strikePriceCents,
        businessesId,
        ...values,
      }),
    ).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.SUBSCRIPTION_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      <Formik
        validationSchema={validationSchema}
        onSubmit={onCreateSubscription}
        initialValues={{
          businesses: [],
          title: "",
          shortDescription: "",
          description: "",
          priceRM: "",
          membershipPriceRM: "",
          strikePriceRM: "",
          numberOfDays: "",
          expireNoDaysFromPurchaseDate: "",
          type: "",
          totalRecurrence: null,
          image: "",
        }}
      >
        {({ values }) => (
          <Form>
            <Grid
              container
              spacing={5}
              sx={{
                padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
                paddingY: theme.dimensions.ScreenPaddingY,
              }}
            >
              <Grid item xs={12}>
                <SpaceBetweenBox>
                  <Text variant="screenLabel">Create Subscription Plan</Text>
                  <Button
                    onClick={() => history.push(routes.SUBSCRIPTION_LIST)}
                    sx={{
                      textTransform: "none",
                      padding: "0px",
                      ":hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Text>Back</Text>
                  </Button>
                </SpaceBetweenBox>
              </Grid>

              <Grid item xs={12}>
                <WhiteBgCardContainer>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <Grid item xs={12}>
                      <Text variant="screenLabel">Subscription Plan</Text>
                    </Grid>
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Businesses</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Businesses
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <SpecificListAutoComplete name="businesses" placeholder="Businesses" />
                            {values?.businesses?.some((item) => item.requireVerification) && (
                              <Text
                                type="YellowColor"
                                sx={{ fontSize: "0.75rem", margin: "3px 14px 0px" }}
                              >
                                Note that the selected business requires user verification
                              </Text>
                            )}
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <FormFieldLabel
                        name="title"
                        placeholder="Enter subscription title"
                        label="Subscription Title"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Short Description
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Short Description
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormFieldTextMultiline
                              placeholder="Enter short description"
                              name="shortDescription"
                              rows={2}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Description</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Description
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormFieldTextMultiline
                              placeholder="Enter description"
                              name="description"
                              rows={5}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormPrice placeholder="00.00" name="priceRM" />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Membership Price
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Membership Price
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormPrice placeholder="00.00" name="membershipPriceRM" />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Strike Price
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Strike Price
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormPrice placeholder="00.00" name="strikePriceRM" />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Number of days
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Number of days
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormFieldText
                              name="numberOfDays"
                              placeholder="0"
                              label="Number of days"
                              type="number"
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                            Expire after purchase (Days)
                          </Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Expire after purchase (Days)
                            </Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormFieldText
                              name="expireNoDaysFromPurchaseDate"
                              placeholder="0"
                              label="Expire after purchase (Days)"
                              type="number"
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Type</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Type</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <TypeSingleSelect
                              name="type"
                              placeholder="Choose type"
                              label="Type"
                              itemList={[
                                { id: 1, label: "One-Off", value: "one_off" },
                                { id: 2, label: "Recurrent", value: "recurrent" },
                              ]}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    {values.type === "recurrent" && (
                      <Grid item xs={12}>
                        {isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Number of Recurrence (Months)
                            </Text>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Number of Recurrence (Months)
                              </Text>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <FormFieldText
                                name="totalRecurrence"
                                placeholder="0"
                                label="Number of Recurrence (Months)"
                                type="number"
                              />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <ImageUpload
                              name="image"
                              filePreview={filePreview}
                              setFilePreview={setFilePreview}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </WhiteBgCardContainer>
              </Grid>
              <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
                <FormSubmitButton
                  width="200px"
                  isLoading={createSubscriptionObj.status === "pending"}
                >
                  <Text type="WhiteColor">Create</Text>
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
